.item {
  background: #f0ebe1;
  margin-bottom: 5px;
  padding: 10px 20px;
}

.content {
  max-height: 0;
  overflow: hidden;
}

.content.show {
  height: auto;
  max-height: 9999px;
}

.skills__container {
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-right: auto;
}
.skills__header {
  display: flex;
  align-self: center;
  margin-bottom: var(--mb-2-5);
  cursor: pointer;
}
.skills__icon,
.skills__arrow {
  font-size: 1.8rem;
  color: var(--first-color);
}
.skills__icon {
  margin-right: 0.5rem;
  /*margin-right: var(--mb-0-75);*/
}
.skills__title {
  font-size: var(--h3-font-size);
}
.skills__subtitle {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
}
.skills__arrow {
  margin-left: auto;
  transition: var(--transition);
}
.skills__list {
  row-gap: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: 1.5rem;
  animation: fade 0.75s ease forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.skills__titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--mb-0-5);
}
.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}
.skills__bar,
.skills__percentage {
  height: 5px;
  border-radius: 0.25rem;
}
.skills__bar {
  background-color: var(--first-color-lighter);
}
.skills__percentage {
  display: block;
  background-color: var(--first-color);
}
.skills__html {
  width: 90%;
}
.skills__css {
  width: 80%;
}
.skills__js {
  width: 60%;
}
.skills__react {
  width: 58%;
}
.skills__mongo {
  width: 45%;
}
.skills__mysql {
  width: 50%;
}
.skills__node {
  width: 55%;
}
.skills__next {
  width: 30%;
}
.skills__xd {
  width: 80%;
}
.skills__illustrator {
  width: 87%;
}
.skills__photoshop {
  width: 90%;
}
.skills__figma {
  width: 85%;
}
.skills__routing {
  width: 50%;
}
.skills__cisco {
  width: 55%;
}
.skills__switching {
  width: 53%;
}
.skills__install {
  width: 65%;
}

.skills__close .skills__list {
  height: 0;
  overflow: hidden;
}
.skills__open .skills__list {
  height: max-content;
  margin-bottom: var(--mb-2-5);
}
