.services__container {
  gap: 1.5rem;
  /*grid-template-columns: repeat(2, 1fr);*/
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: center;
}

.services__content {
  position: relative;
  background-color: var(--first-color);
  padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  color: var(--body-color);
}

.services__content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.services__icon {
  display: block;
  font-size: 1.5rem;
  color: var(--first-color);
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}
