footer {
  background: var(--first-color);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

footer a {
  color: var(--text-color-alt);
}

footer a:hover {
  color: var(--container-color);
}

.footer_logo {
  color: var(--text-color-alt);
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer_socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
  font-size: 1.2rem;
}

.footer_socials a {
  background: var(--text-color-alt);
  color: var(--container-color);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer_socials a:hover {
  background: var(--container-color);
  color: var(--text-color-alt);
}

.footer_copyright {
  margin-bottom: 4rem;
  color: var(--text-color-alt);
}
