header {
  padding-top: 4rem;
  /* overflow: hidden; */

  /* margin-bottom: 35rem; */
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

/** Nav Head **/
.head {
  /*position: fixed;*/
  top: 0;
  left: 0;
  display: flex;
  background-color: transparent;
}
.top_nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.navs_logo {
  color: var(--first-color);
  transition: var(--transition);
}

.navs_logo:hover {
  color: var(--first-color-hover);
}

/*** Header Btn ***/
.header_btn {
  margin-top: 3rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
/*** Header Socials ***/
.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: -150%;
}

.header_socials::after {
  content: "";
  width: 4px;
  height: 2rem;
  background: var(--first-color);
}

.header_social-link {
  width: max-content;
  background-color: var(--first-color-lighter);
  color: var(--first-color);
  transition: var(--transition);
  backdrop-filter: blur(15px);
  padding: 0.25rem;
  border-radius: 0.25rem;
  display: flex;
  font-size: 1rem;
  transition: 0.4s;
}

.header_social-link:hover {
  color: var(--first-color-hover);
}

/*** Profile ***/
.profile {
  background: radial-gradient(
    circle at 50% -20.47%,
    hsl(var(--hue-color), 80%, 85%),
    hsl(var(--hue-color), 80%, 80%),
    hsl(var(--hue-color), 80%, 75%),
    hsl(var(--hue-color), 80%, 70%),
    hsl(var(--hue-color), 80%, 65%),
    hsl(var(--hue-color), 80%, 60%),
    hsl(var(--hue-color), 80%, 55%),
    hsl(var(--hue-color), 80%, 50%),
    hsl(var(--hue-color), 80%, 45%),
    hsl(var(--hue-color), 80%, 40%),
    hsl(var(--hue-color), 80%, 35%),
    hsl(var(--hue-color), 80%, 30%),
    hsl(var(--hue-color), 80%, 25%),
    hsl(var(--hue-color), 80%, 20%),
    hsl(var(--hue-color), 80%, 15%)
  );
  border-radius: 10rem 10rem 1rem 1rem;
  width: 20rem;
  height: 28rem;
  position: absolute;
  left: calc(50% - 10rem);
  /* margin-top: 4rem;*/
  overflow: hidden;
  justify-content: center;
  justify-self: center;
  display: flex;
  align-items: flex-end;
}

/*** Scroll Dowm ***/
.scroll_down {
  color: var(--first-color);
  position: absolute;
  right: -2.3rem;
  bottom: -135%;
  row-gap: 2.25rem;
  display: grid;
  justify-items: center;
}

.scroll_icon {
  font-size: 1.25rem;
}

.scroll_name {
  transform: rotate(-90deg);
}

.header-items {
  margin-top: 6rem;
}
